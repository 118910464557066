<template>
  <div :id="'item-list-' + id">

    <div class="d-flex justify-content-between align-items-center">

      <div v-if="label"
           class="col-8 row"
      >
        <h5 class="align-middle">
          <i class="mr-2"
             :class="icon"
          ></i>
          {{label}}
        </h5>
      </div>

      <div v-if="showSearch"> <!-- Note: currently not used -->
        <b-form-input v-model="dataFilter"
                      :placeholder="searchPlaceholder"
                      size="sm"
        ></b-form-input>
      </div>

      <div>
        <slot name="headerAction"></slot>
      </div>

    </div>
    <div>
      <draggable
        v-model="tableItems"
        handle=".bao-drag-handle"
        @start="drag = true"
        @end="drag = false"
      >
        <div
          v-if="busy"
          id="loading-screen-2011271419"
        >
          <progress-indicator :loadingLabel="loadingText"></progress-indicator>
        </div>
        <div
          v-else
          id="loaded-rows-2011271420"
          class="px-3"
        >
          <play-playbook-btn
            v-for="item in tableItems"
            :key="item.id"
            :class="getDragClasses"
            :favoriteAction="event => handleButtonClick(item, event)"
            :favorite="buttonProps"
            :data="item"
            :icon="playIcon"
            :text="playText"
            @click="handleItemClicked(item)"
          ></play-playbook-btn>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import ProgressIndicator from "./ProgressIndicator"
import PlayPlaybookBtn from "../call/CallViewItem_components/PlayPlaybookBtn"
import draggable from "vuedraggable"

export default {
  name: "BaoItemList",
  components: {
    PlayPlaybookBtn,
    ProgressIndicator,
    draggable
  },
  props: {
    id: {
      type: String,
      default: Math.ceil(Math.random() * Math.random() * Date.now()) + ""
    },
    label: {
      type: String
    },
    items: {
      type: Array
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: "Type to search"
    },
    busy: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "fas fa-star fa-sm"
    },
    playIcon: {
      type: String,
      default: "fas fa-play-circle"
    },
    playText: {
      type: String,
      default: "Start"
    },
    size: {
      type: String,
      default: "medium"
    },
    loadingText: {
      type: String
    },
    buttonProps: {
      type: Object,
      default: null
    },
    isSearching: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataFilter: null
    }
  },
  computed: {
    tableItems: {
      // Note: tableItems is used as a v-model for vue-draggable, thats why it looks like that
      get () {
        return this.items
      },
      set (reOrderedItems) {
        this.$emit("changeOrder", reOrderedItems)
      }
    },
    getDragClasses () {
      return this.buttonProps && this.buttonProps.isActive && !this.isSearching ? "bao-drag-handle" : ""
    }
  },
  methods: {
    handleItemClicked (item) {
      this.$emit("input", item)
    },
    handleButtonClick (item, event) {
      if (event) event.stopPropagation()
      this.$emit("buttonClick", item)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
