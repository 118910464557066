<template>
  <div
    :id="'playbook-start-btn-' + data.id"
    :class="['single-playbook', { 'not-favorite': !!favorite && !favorite.isActive }]"
  >

    <div class="d-flex align-items-center">
      <!-- Favorite button -->
      <div
        v-if="!!favorite"
        role="button"
        :class="['favorite-button', { 'active': favorite.isActive }]"
        :title="favorite.tooltipText"
        variant="secondary rounded"
        @click.stop="event => favoriteAction(event)"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.54.933a.513.513 0 01.92 0l2.066 4.184c.074.152.219.257.386.281l4.618.671c.421.061.59.579.285.876l-3.342 3.257a.513.513 0 00-.148.455l.79 4.599a.513.513 0 01-.746.541l-4.13-2.171a.514.514 0 00-.478 0l-4.13 2.171a.513.513 0 01-.745-.541l.789-4.6a.514.514 0 00-.148-.454L.185 6.945a.513.513 0 01.285-.876l4.618-.67a.513.513 0 00.386-.282L7.54.933z" fill="#D4D5DC"/></svg>
      </div>
      <div :id="'label-' + data.id"
           class="break-word align-middle w-100 label"
      >
        {{ data.name }}
      </div>
    </div>

    <button
      class="start-btn d-flex align-items-center"
      @click="$emit('click')"
    >
      {{ text }}
      <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.235.192A1.5 1.5 0 000 1.5v9a1.5 1.5 0 002.235 1.307l8-4.5a1.5 1.5 0 000-2.614l-8-4.5V.192z" fill="#2A2D52"/></svg>
    </button>
  </div>

</template>

<script>
export default {
  name: "PlayPlaybookBtn",
  props: {
    favoriteAction: {
      type: Function,
      required: true
    },
    favorite: {
      type: Object,
      default: null,
      required: false
    },
    data: {
      type: Object,
      required: true
    },
    text: {
      type: String,
      default: "START",
      required: false
    },
    icon: {
      type: String,
      default: "fas fa-play",
      required: false
    }
  }
}
</script>

<style scoped lang="scss">

.single-playbook {
  padding: 16px 0;
  border-bottom: 1px solid $slate06;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:last-child {
    &.not-favorite {
      border: none;
    }
  }

  .favorite-button {
    margin-right: 8px;
    display: flex;
    align-items: center;
    &.active {
      svg {
        path {
          fill: $slate;
        }
      }
    }
  }

  .start-btn {
    border: 1px solid $black06;
    padding: 14px 16px;
    color: $slate40;
    display: flex;
    align-items: center;
    background-color: transparent;
    transition: 0.3s ease-in;
    border-radius: 12px;
    margin-left: 8px;
    svg {
      margin-left: 8px;
      path {
        transition: 0.3s ease-in;
        fill: $slate60;
      }
    }
    &:hover {
      border-color: $orange;
      background-color: $orange;
      color: white;
      svg {
        path {
          fill: white;
        }
      }
    }
  }
}

.label {
  color: $slate80;
  font-weight: 700;
}

</style>
