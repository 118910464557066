<template>
  <div id="playbook-selection-202101041514"
       class="playbooks-block"
  >
    <!-- Heading -->
    <div class="playbooks-block__heading subTitle">
      <div class="d-flex align-items-center">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-2'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4 1h5.625a.375.375 0 01.369.308l.006.067V7c0 .79.612 1.438 1.387 1.496l.113.004h5.625a.375.375 0 01.369.307l.006.068V17.5a1.5 1.5 0 01-1.387 1.496L16 19H4a1.5 1.5 0 01-1.496-1.387L2.5 17.5v-15c0-.79.612-1.438 1.388-1.496L4 1h5.625H4zm7.125 6V1.646a.113.113 0 01.164-.1l.028.021 5.616 5.616a.112.112 0 01.03.106.113.113 0 01-.075.08l-.035.006H11.5a.375.375 0 01-.369-.308L11.125 7V1.646 7z" fill="#2B2727" opacity=".8"/></svg>
        <span>{{ staticText.playbookLabel }}</span>
      </div>

      <div class="input-wrapper">
        <b-form-input
          v-model="playbookFilter"
          id="playbook-search-input"
          :placeholder="staticText.searchPlaceholderLabel"
          :class="{'empty': !playbookFilter}"
        ></b-form-input>
        <span
          class="search-icon"
          @click="focusPlaybookSearchInput"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.842 13.684a6.801 6.801 0 004.189-1.444L14.79 16 16 14.79l-3.76-3.759a6.8 6.8 0 001.445-4.189A6.85 6.85 0 006.842 0 6.85 6.85 0 000 6.842a6.85 6.85 0 006.842 6.842zm0-11.973a5.137 5.137 0 015.132 5.131 5.137 5.137 0 01-5.132 5.131 5.137 5.137 0 01-5.131-5.131 5.137 5.137 0 015.131-5.131z" fill="#7B7979"/></svg>
        </span>
      </div>
    </div>

    <div
      v-if="playbooksLoading"
      class="no-playbooks"
    >
      <span v-if="!playbookFilter">{{ staticText.noPlaybookAvailable }}</span>
      <span v-else>{{ staticText.noPlaybookMatchingSearch }}</span>
    </div>
    <div
      v-else
      class="d-flex flex-column h-100 overflow-hidden"
    >
      <div class="overflow-y-auto">
        <!--table rows-->
        <bao-item-list
          id="favorite-playbooks"
          :items="shownFavoritePlaybooks"
          :buttonProps="getButtonProps(shownFavoritePlaybooks)"
          :is-searching="isSearching"
          :busy="favLoading"
          :loadingText="staticText.loadingFavoritePlaybooksTextLabel"
          @input="playbook => $emit('start-call', playbook.id)"
          @buttonClick="playbook => handleToggleFavorite(playbook)"
          @changeOrder="changedPlaybooks => handleChangeOrder(changedPlaybooks)"
        ></bao-item-list>

        <bao-item-list
          id="other-playbooks"
          :items="shownOtherPlaybooks"
          :buttonProps="getButtonProps(shownOtherPlaybooks)"
          :is-searching="isSearching"
          :busy="otherLoading"
          :loadingText="staticText.loadingOtherPlaybooksTextLabel"
          @input="playbook => $emit('start-call', playbook.id)"
          @buttonClick="playbook => handleToggleFavorite(playbook)"
          @changeOrder="changedPlaybooks => handleChangeOrder(changedPlaybooks)"
        >
        </bao-item-list>
      </div>
      <div id="show-all-btn-div-202101041515">
        <b-btn id="show-all-btn-202101041515"
               v-if="!showAllPlaybooks && !hideShowAllButton"
               @click="toggleShowAll"
        >
          <i class="fas fa-ellipsis-h pr-1"></i>
          {{ staticText.showAllText }}
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import playbookApp from "../talkscript"
import BaoItemList from "@/apps/base/BaoItemList"

export default {
  name: "CallPlaybookBlock",
  components: { BaoItemList },
  data () {
    return {
      axios,
      playbookApp,
      favLoading: false,
      playbookFilter: null,
      showAllPlaybooks: false,
      playbooks: [],
      playbooksLoaded: false,
      error: false,
      favoritePlaybooks: [],
      otherLoading: false,
      staticTextDefault: {
        playbookLabel: "Playbook",
        searchPlaceholderLabel: "Type to search",
        loadingFavoritePlaybooksTextLabel: "Loading Favorites...",
        loadingOtherPlaybooksTextLabel: "Loading other...",
        showAllText: "Show All",
        noPlaybookAvailable: "No playbooks available",
        noPlaybookMatchingSearch: "No playbooks matching your search"
      }
    }
  },
  mounted () {
    this.getFavoritePlaybooks()
    this.getOtherPlaybooks()
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    hideShowAllButton () {
      return this.playbooks.length < 10 || (this.shownFavoritePlaybooks.length === this.playbooks.length)
    },
    shownOtherPlaybooks () {
      let playbooks = this.playbooks
      if (this.isSearching) {
        playbooks = playbooks.filter(item => this.transformedPlaybookFilter.test(item.name))
      }
      const noPlaybooksToShow = Math.max(10 - this.shownFavoritePlaybooks.length, 0)
      return !this.showAllPlaybooks ? playbooks.slice(0, noPlaybooksToShow) : playbooks
    },
    isSearching () {
      return !!this.playbookFilter && this.playbookFilter.length > 0
    },
    shownFavoritePlaybooks () {
      let playbooks = this.favoritePlaybooks
      if (this.isSearching) {
        playbooks = playbooks.filter(item => this.transformedPlaybookFilter.test(item.name))
      }
      return playbooks
    },
    transformedPlaybookFilter () {
      return new RegExp(this.playbookFilter, "i")
    },
    playbooksLoading () {
      return !(this.favLoading || this.otherLoading) && !(this.shownOtherPlaybooks.length || this.shownFavoritePlaybooks.length)
    }
  },
  methods: {
    getFavoritePlaybooks () {
      this.favLoading = true
      const that = this
      return this.axios.get(
        that.playbookApp.apiUrl.playbook + "/get_favorites?type=default&active=true"
      ).then(response => {
        this.favoritePlaybooks = response.data
      }).catch(error => {
        that.error = error.response
      }).finally(() => {
        that.favLoading = false
      })
    },
    getOtherPlaybooks () {
      const that = this
      this.otherLoading = true
      return this.axios.get(
        that.playbookApp.apiUrl.playbook + "/get_others?type=default&active=true"
      ).then(response => {
        that.playbooks = response.data
        that.playbooksLoaded = true
      }).catch(error => {
        that.error = error.response
      }).finally(() => {
        that.otherLoading = false
      })
    },
    toggleShowAll () {
      this.showAllPlaybooks = !this.showAllPlaybooks
    },
    handleChangeOrder (playbooks) {
      const playbookIDs = playbooks.map(item => item.id)
      this.axios.post(this.playbookApp.apiUrl.playbook + "/set_favorite_order", { playbookIDs })
        .then(response => {
          this.favoritePlaybooks = playbooks
        }).catch(error => {
          this.error = error.response
        })
    },
    handleToggleFavorite (playbook) {
      this.axios.get(this.playbookApp.apiUrl.playbook + "/" + playbook.id + "/set_favorite").then(response => {
        playbook.is_favorite = !playbook.is_favorite
        if (playbook.is_favorite) {
          this.favoritePlaybooks.push(playbook)
          this.playbooks.splice(this.playbooks.indexOf(playbook), 1)
        } else {
          this.playbooks.unshift(playbook)
          this.favoritePlaybooks.splice(this.favoritePlaybooks.indexOf(playbook), 1)
          //  note: non favorite playbooks will be ordered by last_used when page is reloaded.
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    getButtonProps (playbooks) {
      let propsObject
      if (playbooks.length > 0 && playbooks[0].is_favorite) {
        propsObject = {
          isActive: true,
          tooltipText: this.staticText.removeFromFavoritesText,
          buttonIcon: "fas fa-star"
        }
      } else {
        propsObject = {
          isActive: false,
          tooltipText: this.staticText.addToFavoritesText,
          buttonIcon: "far fa-star"
        }
      }
      return propsObject
    },
    focusPlaybookSearchInput () {
      const playbookSearchInput = document.getElementById("playbook-search-input")
      playbookSearchInput.focus()
    }
  }
}
</script>

<style lang="scss" scoped>

.playbooks-block {
  border-radius: 20px;
  background-color: $white40;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  overflow: hidden;
  &__heading {
    background-color: $grey;
    padding: 18px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    color: $slate80;
    svg {
      path {
        fill: $slate80;
        fill-opacity: 1;
        opacity: 1;
      }
    }
    .input-wrapper {
      input::placeholder {
         color: transparent;
    }
      .form-control {
        height: 32px;
        border-radius: 8px;
        padding: 8px 35px 8px 16px;
        background-color: transparent;
        border: 1px solid $slate20;
        &:not(:focus) {
          &.empty {
            width: 32px;
            padding: 0 30px 0 0;
            border-radius: 50%;
            + .search-icon {
              width: 100%;
              right: 0;
            }
          }
        }
      }
      .search-icon {
        color: $slate80;
        position: absolute;
        height: 100%;
        margin: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: text;
        svg {
          height: 12px;
          width: 12px;
          path {
            fill: $slate80
          }
        }
        right: 10px;
      }
    }
  }
  .no-playbooks {
    padding: 32px 16px;
    color: $slate60;
  }
}

#show-all-btn-div-202101041515 {
  display: flex;
  justify-content: center;
  padding: 8px;
}

</style>
