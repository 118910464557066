<template>
  <div class="container-fluid">
    <div  v-if="!!widgetPage">
      <div v-if="!widgetsAvailable"
           class="d-flex align-items-center justify-content-center"
      >
        <bao-dashboard-no-widget-display @add-widget="goToWidgetPage"></bao-dashboard-no-widget-display>
      </div>

      <div v-for="(widget, index) in widgetPage.widgets"
           :key="`${index}-${widget.id}`"
           v-else
           class="d-flex mb-2"
      >
        <component
          :is="widget.component"
          v-bind="{widget}"
        ></component>
      </div>
    </div>
    <div v-else>
      <bao-dashboard-no-widget-display
        :front-page-selected="false"
        @add-widget="goToDashboard"
      ></bao-dashboard-no-widget-display>
    </div>
  </div>
</template>

<script>

import BaoDashboardNoWidgetDisplay from "@/apps/dashboard/BaoDashboardComponents/BaoDashboardNoWidgetDisplay"

export default {
  name: "BaoDashboardFrontPage",
  components: { BaoDashboardNoWidgetDisplay },
  props: {
    widgetPage: {
      type: Object,
      default: null
    }
  },
  computed: {
    widgetsAvailable () {
      return this.widgetPage.widgets.length > 0
    }
  },
  methods: {
    goToWidgetPage () {
      this.$router.replace("/dashboard/" + this.widgetPage.id)
    },
    goToDashboard () {
      this.$router.replace("/dashboard")
    }
  }
}
</script>

<style scoped lang="scss">

</style>
