<template>
  <div>
    <div class="input-wrapper d-flex-h-center">
      <img src="/img/icons/vector.svg"
           class="widget-icon mb-2"
      />
    </div>
    <div class="font-size-20 font-weight-bold text-center">
      {{ staticText.noWidgetsMessage }}
    </div>
    <p class="font-size-16 opacity-80 text-center">
      {{ addWidgetsMessage }}
    </p>
    <div class="d-flex-h-center w-100"
         v-if="canModifyWidgetPage(widgetPage)"
    >
      <b-btn
        id="show-widget-sidebar-202103051221"
        variant="primary"
        @click="$emit('add-widget')"
      >
        {{ frontPageSelected ? staticText.addWidget : staticText.goToDashboard }}
      </b-btn>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex"

export default {
  name: "BaoDashboardNoWidgetDisplay",
  data () {
    return {
      staticTextDefault: {
        addWidget: "Add Widgets",
        noWidgetsMessage: "No Widgets available yet",
        addWidgetMessage: "Edit your dashboard now and add some widgets.",
        addWidgetPageMessage: "Choose a dashboard to display the widgets here.",
        goToDashboard: "Go to dashboards",
        askOwnerOrAdminMessage: "Ask your admin or the owner of the dashboard to add some widgets."
      }
    }
  },
  props: {
    frontPageSelected: {
      type: Boolean,
      default: true
    },
    widgetPage: {
      type: Object,
      required: false
    }

  },
  computed: {
    ...mapGetters({
      canModifyWidgetPage: "dashboardStore/canModifyWidgetPage"
    }),
    addWidgetsMessage () {
      if (!this.frontPageSelected) return this.staticText.addWidgetPageMessage
      if (this.canModifyWidgetPage(this.widgetPage)) return this.staticText.addWidgetMessage
      return this.staticText.askOwnerOrAdminMessage
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}

</script>

<style scoped lang="scss">

.widget-icon {
  height: 25px;
  width: 25px;
}

.d-flex-h-center {
  display: flex;
  justify-content: center;
}

</style>
